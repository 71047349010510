import { createStylesParams, StyleParamType } from '@wix/tpa-settings';
import { type CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import { TagLayout } from '@wix/communities-blog-client-common';
import {
  getFlexAlignmentValue,
  resolveDefaultStyleParamValue,
  type StyleParamsValues,
} from '@app/common/style-params';
import * as LEGACY_STYLE_PARAMS from './legacyStylesParams';

type StyleParams = {
  showPostCountV2: StyleParamType.Boolean;
  orderTagsV2: StyleParamType.Number;
  numberOfTagsV2: StyleParamType.Number;
  tagLayoutV2: StyleParamType.Number;
  buttonTagTextFontV2: StyleParamType.Font;
  buttonTagTextColorV2: StyleParamType.Color;
  buttonTagTextColorHoverV2: StyleParamType.Color;
  buttonTagBackgroundColorV2: StyleParamType.Color;
  buttonTagBackgroundColorHoverV2: StyleParamType.Color;
  buttonTagBorderColorV2: StyleParamType.Color;
  buttonTagBorderColorHoverV2: StyleParamType.Color;
  buttonTagBorderWidthV2: StyleParamType.Number;
  buttonTagCornerRadiusV2: StyleParamType.Number;
  buttonTagAlignmentV2: StyleParamType.Number;
  buttonTagVerticalPaddingV2: StyleParamType.Number;
  buttonTagHorizontalPaddingV2: StyleParamType.Number;
  buttonTagVerticalSpacingV2: StyleParamType.Number;
  buttonTagHorizontalSpacingV2: StyleParamType.Number;
  textTagTextFontV2: StyleParamType.Font;
  textTagTextColorV2: StyleParamType.Color;
  textTagTextColorHoverV2: StyleParamType.Color;
  textTagAlignmentV2: StyleParamType.Number;
  textTagVerticalSpacingV2: StyleParamType.Number;
  textTagHorizontalSpacingV2: StyleParamType.Number;
};

export default createStylesParams<StyleParams>({
  showPostCountV2: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACY_STYLE_PARAMS.showPostCount,
        mobileParamName: LEGACY_STYLE_PARAMS.showPostCountMobile,
        dependencyParam: LEGACY_STYLE_PARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  orderTagsV2: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACY_STYLE_PARAMS.orderTags,
        mobileParamName: LEGACY_STYLE_PARAMS.orderTagsMobile,
        dependencyParam: LEGACY_STYLE_PARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  numberOfTagsV2: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACY_STYLE_PARAMS.numberOfTags,
        mobileParamName: LEGACY_STYLE_PARAMS.numberOfTagsMobile,
        dependencyParam: LEGACY_STYLE_PARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  tagLayoutV2: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACY_STYLE_PARAMS.tagLayout,
        mobileParamName: LEGACY_STYLE_PARAMS.tagLayoutMobile,
        dependencyParam: LEGACY_STYLE_PARAMS.isMobileLayoutSettingsEnabled,
      }),
  },

  buttonTagTextFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACY_STYLE_PARAMS.buttonTagTextFont,
        mobileParamName: LEGACY_STYLE_PARAMS.buttonTagTextFontMobile,
        dependencyParam: LEGACY_STYLE_PARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  buttonTagTextColorV2: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACY_STYLE_PARAMS.buttonTagTextColor,
        mobileParamName: LEGACY_STYLE_PARAMS.buttonTagTextColorMobile,
        dependencyParam: LEGACY_STYLE_PARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  buttonTagTextColorHoverV2: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACY_STYLE_PARAMS.buttonTagTextColorHover,
        mobileParamName: LEGACY_STYLE_PARAMS.buttonTagTextColorHoverMobile,
        dependencyParam: LEGACY_STYLE_PARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  buttonTagBackgroundColorV2: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACY_STYLE_PARAMS.buttonTagBackgroundColor,
        mobileParamName: LEGACY_STYLE_PARAMS.buttonTagBackgroundColorMobile,
        dependencyParam: LEGACY_STYLE_PARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  buttonTagBackgroundColorHoverV2: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACY_STYLE_PARAMS.buttonTagBackgroundColorHover,
        mobileParamName: LEGACY_STYLE_PARAMS.buttonTagBackgroundColorHoverMobile,
        dependencyParam: LEGACY_STYLE_PARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  buttonTagBorderColorV2: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACY_STYLE_PARAMS.buttonTagBorderColor,
        mobileParamName: LEGACY_STYLE_PARAMS.buttonTagBorderColorMobile,
        dependencyParam: LEGACY_STYLE_PARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  buttonTagBorderColorHoverV2: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACY_STYLE_PARAMS.buttonTagBorderColorHover,
        mobileParamName: LEGACY_STYLE_PARAMS.buttonTagBorderColorHoverMobile,
        dependencyParam: LEGACY_STYLE_PARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  buttonTagBorderWidthV2: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACY_STYLE_PARAMS.buttonTagBorderWidth,
        mobileParamName: LEGACY_STYLE_PARAMS.buttonTagBorderWidthMobile,
        dependencyParam: LEGACY_STYLE_PARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  buttonTagCornerRadiusV2: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACY_STYLE_PARAMS.buttonTagCornerRadius,
        mobileParamName: LEGACY_STYLE_PARAMS.buttonTagCornerRadiusMobile,
        dependencyParam: LEGACY_STYLE_PARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  buttonTagAlignmentV2: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACY_STYLE_PARAMS.buttonTagAlignment,
        mobileParamName: LEGACY_STYLE_PARAMS.buttonTagAlignmentMobile,
        dependencyParam: LEGACY_STYLE_PARAMS.isMobileLayoutSettingsEnabled,
      }),
  },
  buttonTagVerticalPaddingV2: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACY_STYLE_PARAMS.buttonTagVerticalPadding,
        mobileParamName: LEGACY_STYLE_PARAMS.buttonTagVerticalPaddingMobile,
        dependencyParam: LEGACY_STYLE_PARAMS.isMobileLayoutSettingsEnabled,
      }),
  },
  buttonTagHorizontalPaddingV2: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACY_STYLE_PARAMS.buttonTagHorizontalPadding,
        mobileParamName: LEGACY_STYLE_PARAMS.buttonTagHorizontalPaddingMobile,
        dependencyParam: LEGACY_STYLE_PARAMS.isMobileLayoutSettingsEnabled,
      }),
  },
  buttonTagVerticalSpacingV2: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACY_STYLE_PARAMS.buttonTagVerticalSpacing,
        mobileParamName: LEGACY_STYLE_PARAMS.buttonTagVerticalSpacingMobile,
        dependencyParam: LEGACY_STYLE_PARAMS.isMobileLayoutSettingsEnabled,
      }),
  },
  buttonTagHorizontalSpacingV2: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACY_STYLE_PARAMS.buttonTagButtonTagHorizontalSpacing,
        mobileParamName: LEGACY_STYLE_PARAMS.buttonTagButtonTagHorizontalSpacingMobile,
        dependencyParam: LEGACY_STYLE_PARAMS.isMobileLayoutSettingsEnabled,
      }),
  },

  textTagTextFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACY_STYLE_PARAMS.textTagTextFont,
        mobileParamName: LEGACY_STYLE_PARAMS.textTagTextFontMobile,
        dependencyParam: LEGACY_STYLE_PARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  textTagTextColorV2: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACY_STYLE_PARAMS.textTagTextColor,
        mobileParamName: LEGACY_STYLE_PARAMS.textTagTextColorMobile,
        dependencyParam: LEGACY_STYLE_PARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  textTagTextColorHoverV2: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACY_STYLE_PARAMS.textTagTextColorHover,
        mobileParamName: LEGACY_STYLE_PARAMS.textTagTextColorHoverMobile,
        dependencyParam: LEGACY_STYLE_PARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  textTagVerticalSpacingV2: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACY_STYLE_PARAMS.textTagVerticalSpacing,
        mobileParamName: LEGACY_STYLE_PARAMS.textTagVerticalSpacingMobile,
        dependencyParam: LEGACY_STYLE_PARAMS.isMobileLayoutSettingsEnabled,
      }),
  },
  textTagHorizontalSpacingV2: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACY_STYLE_PARAMS.textTagHorizontalSpacing,
        mobileParamName: LEGACY_STYLE_PARAMS.textTagHorizontalSpacingMobile,
        dependencyParam: LEGACY_STYLE_PARAMS.isMobileLayoutSettingsEnabled,
      }),
  },
  textTagAlignmentV2: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACY_STYLE_PARAMS.textTagAlignment,
        mobileParamName: LEGACY_STYLE_PARAMS.textTagAlignmentMobile,
        dependencyParam: LEGACY_STYLE_PARAMS.isMobileLayoutSettingsEnabled,
      }),
  },
});

export const customCssVars: CustomCssVarsFn<StyleParamsValues<StyleParams>> = ({
  styleParams,
  isRTL,
}) => {
  const layout = styleParams.numbers.tagLayoutV2;

  return {
    showPostCount: styleParams.booleans.showPostCountV2 ? 'inline' : 'none',

    textColor:
      layout === TagLayout.Button
        ? styleParams.colors.buttonTagTextColorV2
        : styleParams.colors.textTagTextColorV2,
    textColorHover:
      layout === TagLayout.Button
        ? styleParams.colors.buttonTagTextColorHoverV2
        : styleParams.colors.textTagTextColorHoverV2,
    textFontFamily:
      layout === TagLayout.Button
        ? styleParams.fonts.buttonTagTextFontV2.family.join(', ')
        : styleParams.fonts.textTagTextFontV2.family.join(', '),
    textLineHeight:
      layout === TagLayout.Button
        ? styleParams.fonts.buttonTagTextFontV2.lineHeight
        : styleParams.fonts.textTagTextFontV2.lineHeight,
    textFontSize:
      layout === TagLayout.Button
        ? styleParams.fonts.buttonTagTextFontV2.size
        : styleParams.fonts.textTagTextFontV2.size,
    textFontStyle:
      layout === TagLayout.Button
        ? styleParams.fonts.buttonTagTextFontV2.style
        : styleParams.fonts.textTagTextFontV2.style,
    textDecoration:
      layout === TagLayout.Button
        ? styleParams.fonts.buttonTagTextFontV2.underline
          ? 'underline'
          : 'none'
        : styleParams.fonts.textTagTextFontV2.underline
        ? 'underline'
        : 'none',
    textFontWeight:
      layout === TagLayout.Button
        ? styleParams.fonts.buttonTagTextFontV2.weight
        : styleParams.fonts.textTagTextFontV2.weight,
    textFontVariant:
      layout === TagLayout.Button
        ? styleParams.fonts.buttonTagTextFontV2.variant
        : styleParams.fonts.textTagTextFontV2.variant,

    backgroundColor:
      layout === TagLayout.Button ? styleParams.colors.buttonTagBackgroundColorV2 : 'transparent',

    backgroundColorHover:
      layout === TagLayout.Button
        ? styleParams.colors.buttonTagBackgroundColorHoverV2
        : 'transparent',

    borderWidth: layout === TagLayout.Button ? styleParams.numbers.buttonTagBorderWidthV2 : 0,
    borderStyle: layout === TagLayout.Button ? 'solid' : 'none',
    borderRadius: layout === TagLayout.Button ? styleParams.numbers.buttonTagCornerRadiusV2 : 0,
    borderColor:
      layout === TagLayout.Button ? styleParams.colors.buttonTagBorderColorV2 : 'transparent',
    borderColorHover:
      layout === TagLayout.Button ? styleParams.colors.buttonTagBorderColorHoverV2 : 'transparent',

    textAlignment: getFlexAlignmentValue(
      layout === TagLayout.Button
        ? styleParams.numbers.buttonTagAlignmentV2
        : styleParams.numbers.textTagAlignmentV2,
      isRTL,
    ),

    verticalSpacing:
      layout === TagLayout.Button
        ? styleParams.numbers.buttonTagVerticalSpacingV2
        : styleParams.numbers.textTagVerticalSpacingV2,
    horizontalSpacing:
      layout === TagLayout.Button
        ? styleParams.numbers.buttonTagHorizontalSpacingV2
        : styleParams.numbers.textTagHorizontalSpacingV2,

    verticalPadding:
      layout === TagLayout.Button ? styleParams.numbers.buttonTagVerticalPaddingV2 : 0,
    horizontalPadding:
      layout === TagLayout.Button ? styleParams.numbers.buttonTagHorizontalPaddingV2 : 0,
  };
};
