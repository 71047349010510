import { createStylesParam, StyleParamType, wixFontParam, wixColorParam } from '@wix/tpa-settings';
import { TagAlignment, TagLayout, TagOrder } from '@wix/communities-blog-client-common';

export const showPostCount = createStylesParam('tags-showPostCount', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => false,
});

export const showPostCountMobile = createStylesParam('tags-mobile-showPostCount', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => false,
});

export const orderTags = createStylesParam('tags-orderTags', {
  type: StyleParamType.Number,
  getDefaultValue: () => TagOrder.MostUsed,
});

export const orderTagsMobile = createStylesParam('tags-mobile-orderTags', {
  type: StyleParamType.Number,
  getDefaultValue: () => TagOrder.MostUsed,
});

export const numberOfTags = createStylesParam('tags-numberOfTags', {
  type: StyleParamType.Number,
  getDefaultValue: () => 75,
});

export const numberOfTagsMobile = createStylesParam('tags-mobile-numberOfTags', {
  type: StyleParamType.Number,
  getDefaultValue: () => 75,
});

export const isMobileDisplaySettingsEnabled = createStylesParam(
  'tags-mobile-isDisplaySettingsEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
);

export const isMobileDesignSettingsEnabled = createStylesParam(
  'tags-mobile-isDesignSettingsEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
);

export const isMobileLayoutSettingsEnabled = createStylesParam(
  'tags-mobile-isLayoutSettingsEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
);

export const tagLayout = createStylesParam('tagLayout', {
  type: StyleParamType.Number,
  getDefaultValue: () => TagLayout.Button,
});

export const tagLayoutMobile = createStylesParam('tagLayoutMobile', {
  type: StyleParamType.Number,
  getDefaultValue: () => TagLayout.Button,
});

export const buttonTagTextFont = createStylesParam('tags-buttonTag-textFont', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Body-M', { size: 14 }),
});

export const buttonTagTextFontMobile = createStylesParam('tags-mobile-buttonTag-textFont', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Body-M', { size: 14 }),
});

export const buttonTagTextColor = createStylesParam('tags-buttonTag-textColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
});

export const buttonTagTextColorMobile = createStylesParam('tags-mobile-buttonTag-textColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
});

export const buttonTagTextColorHover = createStylesParam('tags-buttonTag-textHoverColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
});

export const buttonTagTextColorHoverMobile = createStylesParam(
  'tags-mobile-buttonTag-textHoverColor',
  {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
);

export const buttonTagBackgroundColor = createStylesParam('tags-buttonTag-backgroundColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-1', 1),
});

export const buttonTagBackgroundColorMobile = createStylesParam(
  'tags-mobile-buttonTag-backgroundColor',
  {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
);

export const buttonTagBackgroundColorHover = createStylesParam(
  'tags-buttonTag-backgroundHoverColor',
  {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
);

export const buttonTagBackgroundColorHoverMobile = createStylesParam(
  'tags-mobile-buttonTag-backgroundHoverColor',
  {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
);

export const buttonTagBorderColor = createStylesParam('tags-buttonTag-borderColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5', 0.2),
});

export const buttonTagBorderColorMobile = createStylesParam('tags-mobile-buttonTag-borderColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5', 0.2),
});

export const buttonTagBorderColorHover = createStylesParam('tags-buttonTag-borderHoverColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5', 0.6),
});

export const buttonTagBorderColorHoverMobile = createStylesParam(
  'tags-mobile-buttonTag-borderHoverColor',
  {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.6),
  },
);

export const buttonTagBorderWidth = createStylesParam('tags-buttonTag-borderWidth', {
  type: StyleParamType.Number,
  getDefaultValue: () => 1,
});

export const buttonTagBorderWidthMobile = createStylesParam('tags-mobile-buttonTag-borderWidth', {
  type: StyleParamType.Number,
  getDefaultValue: () => 1,
});

export const buttonTagCornerRadius = createStylesParam('tags-buttonTag-cornerRadius', {
  type: StyleParamType.Number,
  getDefaultValue: () => 0,
});

export const buttonTagCornerRadiusMobile = createStylesParam('tags-mobile-buttonTag-cornerRadius', {
  type: StyleParamType.Number,
  getDefaultValue: () => 0,
});

export const buttonTagAlignment = createStylesParam('tags-buttonTag-alignment', {
  type: StyleParamType.Number,
  getDefaultValue: () => TagAlignment.Left,
});

export const buttonTagAlignmentMobile = createStylesParam('tags-mobile-buttonTag-alignment', {
  type: StyleParamType.Number,
  getDefaultValue: () => TagAlignment.Left,
});

export const buttonTagVerticalPadding = createStylesParam('tags-buttonTag-verticalPadding', {
  type: StyleParamType.Number,
  getDefaultValue: () => 6,
});
export const buttonTagVerticalPaddingMobile = createStylesParam(
  'tags-mobile-buttonTag-verticalPadding',
  {
    type: StyleParamType.Number,
    getDefaultValue: () => 6,
  },
);

export const buttonTagHorizontalPadding = createStylesParam('tags-buttonTag-horizontalPadding', {
  type: StyleParamType.Number,
  getDefaultValue: () => 12,
});

export const buttonTagHorizontalPaddingMobile = createStylesParam(
  'tags-mobile-buttonTag-horizontalPadding',
  {
    type: StyleParamType.Number,
    getDefaultValue: () => 12,
  },
);

export const buttonTagVerticalSpacing = createStylesParam('tags-buttonTag-verticalSpacing', {
  type: StyleParamType.Number,
  getDefaultValue: () => 8,
});
export const buttonTagVerticalSpacingMobile = createStylesParam(
  'tags-mobile-buttonTag-verticalSpacing',
  {
    type: StyleParamType.Number,
    getDefaultValue: () => 8,
  },
);

export const buttonTagButtonTagHorizontalSpacing = createStylesParam(
  'tags-buttonTag-horizontalSpacing',
  {
    type: StyleParamType.Number,
    getDefaultValue: () => 8,
  },
);
export const buttonTagButtonTagHorizontalSpacingMobile = createStylesParam(
  'tags-mobile-buttonTag-horizontalSpacing',
  {
    type: StyleParamType.Number,
    getDefaultValue: () => 8,
  },
);

export const textTagTextFont = createStylesParam('tags-textTag-textFont', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Body-M', { size: 14 }),
});

export const textTagTextFontMobile = createStylesParam('tags-mobile-textTag-textFont', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Body-M', { size: 14 }),
});

export const textTagTextColor = createStylesParam('tags-textTag-textColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
});

export const textTagTextColorMobile = createStylesParam('tags-mobile-textTag-textColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
});

export const textTagTextColorHover = createStylesParam('tags-textTag-textHoverColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-8'),
});

export const textTagTextColorHoverMobile = createStylesParam('tags-mobile-textTag-textHoverColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-8'),
});

export const textTagVerticalSpacing = createStylesParam('tags-textTag-verticalSpacing', {
  type: StyleParamType.Number,
  getDefaultValue: () => 12,
});

export const textTagVerticalSpacingMobile = createStylesParam(
  'tags-mobile-textTag-verticalSpacing',
  {
    type: StyleParamType.Number,
    getDefaultValue: () => 12,
  },
);

export const textTagHorizontalSpacing = createStylesParam('tags-textTag-horizontalSpacing', {
  type: StyleParamType.Number,
  getDefaultValue: () => 12,
});

export const textTagHorizontalSpacingMobile = createStylesParam(
  'tags-mobile-textTag-horizontalSpacing',
  {
    type: StyleParamType.Number,
    getDefaultValue: () => 12,
  },
);

export const textTagAlignment = createStylesParam('tags-textTag-alignment', {
  type: StyleParamType.Number,
  getDefaultValue: () => TagAlignment.Left,
});

export const textTagAlignmentMobile = createStylesParam('tags-mobile-textTag-alignment', {
  type: StyleParamType.Number,
  getDefaultValue: () => TagAlignment.Left,
});
